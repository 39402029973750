import React from "react";
import {
  ElementsSelect,
  type Language,
  LanguageSelect,
  type LanguageTitle,
  NOT_SET,
  PrettySelect,
  getLanguageFromTitle,
  getLanguageTitle,
  getOptionValue,
} from "../helpers/Forms";
import { useTranslation } from "react-i18next";
import { TextAreaForVariables } from "../helpers/TextAreas";
import { type InputConversion, type Elements } from "./ContextProvider";
import HighlightWithinTextarea from "react-highlight-within-textarea";

interface ManualKind {
  kind: "manual";
  content: string;
  temperature: number;
}

interface CategoryKind {
  kind: "excel:choose-category";
  element: string;
  categories: string;
  temperature: number;
}

interface SizeKind {
  kind: "excel:choose-size";
  element: string;
  sizes: string;
  temperature: number;
}

interface ColorKind {
  kind: "excel:choose-color";
  element: string;
  colors: string;
  temperature: number;
}

interface ManufacturerKind {
  kind: "excel:choose-manufacturer";
  element: string;
  temperature: number;
}

interface RewriteTextKind {
  kind: "copywriter:rewrite-text";
  element: string;
  language: LanguageTitle;
  temperature: number;
}

interface RewriteToShortTextKind {
  kind: "copywriter:rewrite-to-short-text";
  element: string;
  language: LanguageTitle;
  temperature: number;
}

export type QuestionKind =
  | ManualKind["kind"]
  | CategoryKind["kind"]
  | SizeKind["kind"]
  | ColorKind["kind"]
  | ManufacturerKind["kind"]
  | RewriteTextKind["kind"]
  | RewriteToShortTextKind["kind"];
export type Question =
  | ManualKind
  | CategoryKind
  | SizeKind
  | ColorKind
  | ManufacturerKind
  | RewriteTextKind
  | RewriteToShortTextKind;

export interface TemperatureSliderProps {
  value: number;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface QuestionKindSelectProps {
  kind: QuestionKind;
  onChange: (value: QuestionKind | null) => void;
}

export type ManualQuestionAreaProps = {
  onChange: (value: Partial<ManualKind>) => void;
  elements: Elements;
} & ManualKind;

export type CategoryQuestionAreaProps = {
  onChange: (value: Partial<CategoryKind>) => void;
  elements: Elements;
} & CategoryKind;

export type SizeQuestionAreaProps = {
  onChange: (value: Partial<SizeKind>) => void;
  elements: Elements;
} & SizeKind;

export type ColorQuestionAreaProps = {
  onChange: (value: Partial<ColorKind>) => void;
  elements: Elements;
} & ColorKind;

export type ManufacturerQuestionAreaProps = {
  onChange: (value: Partial<ManufacturerKind>) => void;
  elements: Elements;
} & ManufacturerKind;

export type RewriteTextQuestionAreaProps = {
  onChange: (value: Partial<RewriteTextKind>) => void;
  elements: Elements;
} & RewriteTextKind;

export type RewriteToShortTextQuestionAreaProps = {
  onChange: (value: Partial<RewriteToShortTextKind>) => void;
  elements: Elements;
} & RewriteToShortTextKind;

export function invalidInputForOpenAI(
  inputType: InputConversion["type"] | null,
  inputArgs: InputConversion["args"] | null,
) {
  return (
    inputType === "OPEN_AI_QUESTION" &&
    new QuestionHelper(inputArgs as Question).empty()
  );
}

export class QuestionHelper {
  question: Question;

  constructor(question: Question) {
    this.question = question;
  }

  empty(): boolean {
    switch (this.question.kind) {
      case "manual":
        return this.question.content === NOT_SET;
      case "excel:choose-category":
        return (
          this.question.element === NOT_SET ||
          this.question.categories.length === 0
        );
      case "excel:choose-size":
        return (
          this.question.element === NOT_SET || this.question.sizes.length === 0
        );
      case "excel:choose-color":
        return (
          this.question.element === NOT_SET || this.question.colors.length === 0
        );
      case "excel:choose-manufacturer":
        return this.question.element === NOT_SET;
      case "copywriter:rewrite-text":
        return (
          this.question.element === NOT_SET ||
          this.question.language.length === 0
        );
      case "copywriter:rewrite-to-short-text":
        return (
          this.question.element === NOT_SET ||
          this.question.language.length === 0
        );
    }
  }

  isExcel(): boolean {
    return this.question.kind.startsWith("excel:");
  }

  getOutputTypes() {
    switch (this.question.kind) {
      case "copywriter:rewrite-text":
      case "copywriter:rewrite-to-short-text":
        return ["GPT35_ANSWER", "GPT40_ANSWER", "GPT4O_ANSWER"];
      default:
        return undefined;
    }
  }

  getSliderForKind(
    onChangeTemperature: (event: React.ChangeEvent<HTMLInputElement>) => void,
  ) {
    switch (this.question.kind) {
      case "excel:choose-category":
      case "excel:choose-size":
      case "excel:choose-color":
      case "excel:choose-manufacturer":
      case "copywriter:rewrite-text":
      case "copywriter:rewrite-to-short-text":
        return <></>;
      default:
        return (
          <TemperatureSlider
            value={this.question.temperature}
            onChange={onChangeTemperature}
          />
        );
    }
  }

  getAreaForKind(
    onChange: (value: Partial<Question>) => void,
    elements: Elements,
  ) {
    switch (this.question.kind) {
      case "manual":
        return (
          <ManualQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
      case "excel:choose-category":
        return (
          <CategoryQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
      case "excel:choose-size":
        return (
          <SizeQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
      case "excel:choose-color":
        return (
          <ColorQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
      case "excel:choose-manufacturer":
        return (
          <ManufacturerQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
      case "copywriter:rewrite-text":
      case "copywriter:rewrite-to-short-text":
        return (
          <RewriteTextQuestionArea
            {...this.question}
            onChange={onChange}
            elements={elements}
          />
        );
    }
  }

  getInputName(): string {
    switch (this.question.kind) {
      case "manual":
        return this.question.content;
      default:
        return this.question.element;
    }
  }

  getFullOutputType(model: string): string {
    switch (this.question.kind) {
      case "excel:choose-category":
      case "excel:choose-size":
      case "excel:choose-color":
      case "excel:choose-manufacturer":
        return this.question.kind
          .replace("excel:", "")
          .replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase();
          });
      case "copywriter:rewrite-text":
        return "rewriteText";
      case "copywriter:rewrite-to-short-text":
        return "rewriteToShortText";
      default:
        return model;
    }
  }

  static getDefaultArgs(kind: QuestionKind): Question {
    switch (kind) {
      case "manual":
        return {
          kind,
          temperature: 0.5,
          content: "",
        };
      case "excel:choose-category":
        return {
          kind,
          temperature: 0.5,
          categories: "",
          element: NOT_SET,
        };
      case "excel:choose-size":
        return {
          kind,
          temperature: 0.5,
          element: NOT_SET,
          sizes: "small, medium, large",
        };
      case "excel:choose-color":
        return {
          kind,
          temperature: 0.5,
          element: NOT_SET,
          colors:
            "black, white, red, green, yellow, blue, brown, orange, pink, purple, grey",
        };
      case "copywriter:rewrite-text":
        return {
          kind,
          temperature: 0.5,
          element: NOT_SET,
          language: "Czech",
        };
      case "copywriter:rewrite-to-short-text":
        return {
          kind,
          temperature: 0.5,
          element: NOT_SET,
          language: "Czech",
        };
      case "excel:choose-manufacturer":
        return {
          kind,
          temperature: 0.5,
          element: NOT_SET,
        };
    }
  }
}

export function TemperatureSlider({ value, onChange }: TemperatureSliderProps) {
  const { t } = useTranslation();
  return (
    <div className="input-group temperature-slider">
      <label htmlFor="gpt-temperature" className="never-highlight-error">
        {t("element.textarea.temperature.label")}
      </label>
      <input
        type="range"
        min="0"
        max="2"
        step="0.1"
        value={value}
        id="gpt-temperature"
        name="gpt-temperature"
        onChange={onChange}
        className="never-highlight-error"
      />
      <span className="slider-value">{value}</span>
    </div>
  );
}

export function QuestionKindSelect({
  kind,
  onChange,
}: QuestionKindSelectProps) {
  const { t } = useTranslation();
  const defaultOption = {
    value: "manual",
    label: t("element.prompt.kindSelect.manual.label"),
  };
  const options = [
    defaultOption,
    {
      value: "excel:choose-category",
      label: t("element.prompt.kindSelect.category.label"),
    },
    {
      value: "excel:choose-size",
      label: t("element.prompt.kindSelect.size.label"),
    },
    {
      value: "excel:choose-color",
      label: t("element.prompt.kindSelect.color.label"),
    },
    {
      value: "excel:choose-manufacturer",
      label: t("element.prompt.kindSelect.manufacturer.label"),
    },
    {
      value: "copywriter:rewrite-text",
      label: `${t("element.prompt.kindSelect.rewriteText.label")} (BETA)`,
    },
    {
      value: "copywriter:rewrite-to-short-text",
      label: `${t(
        "element.prompt.kindSelect.rewriteToShortText.label",
      )} (BETA)`,
    },
  ];
  const selectedOption =
    options.find((option) => option.value === kind) || defaultOption;

  return (
    <div className="input-group prompt-kind-select">
      <label htmlFor="prompt-kind-selector" className="never-highlight-error">
        {t("element.prompt.kindSelect.label")}
      </label>
      <PrettySelect
        inputId="prompt-kind-selector"
        options={options}
        onChange={(option) => {
          onChange(getOptionValue<QuestionKind>(option));
        }}
        value={selectedOption}
      />
    </div>
  );
}

export function ManualQuestionArea({
  content,
  onChange,
  elements,
}: ManualQuestionAreaProps) {
  const { t } = useTranslation();
  return (
    <div className="input-group manual-prompt-textarea">
      <label>
        {`${t("element.textarea.input.label")} (${t(
          "element.textarea.input.label.example",
        )} `}
        <code>%ITEM_ID%</code>
        {")"}
      </label>
      <TextAreaForVariables
        value={content}
        onChange={(content) => {
          onChange({ content });
        }}
        placeholder={""}
        elements={elements}
      />
    </div>
  );
}

export function CategoryQuestionArea({
  element,
  categories,
  onChange,
  elements,
}: CategoryQuestionAreaProps) {
  const { t } = useTranslation();
  function onChangeSelect(element: string | null) {
    if (element) {
      onChange({ element });
    }
  }
  function onChangeTextArea(textValue: string) {
    categories = textValue;
    onChange({ categories });
  }

  return (
    <div>
      <div className="input-group category-prompt-select">
        <label htmlFor="category-element-input-name-select">
          {t("element.prompt.categorySelect.element.label")}
        </label>
        <ElementsSelect
          inputId="category-element-input-name-select"
          elements={elements}
          value={element}
          onChange={onChangeSelect}
          loading={false}
        />
      </div>
      <div className="input-group category-prompt-textarea">
        <label>{t("element.prompt.categorySelect.textarea.label")}</label>
        <div className="highlighted-area small">
          <HighlightWithinTextarea
            value={categories}
            highlight={[
              {
                highlight: /(?!\s)([^,]+)/g,
                className: "green",
              },
            ]}
            onChange={onChangeTextArea}
            placeholder={""}
          />
        </div>
      </div>
    </div>
  );
}

export function SizeQuestionArea({
  element,
  sizes,
  onChange,
  elements,
}: SizeQuestionAreaProps) {
  const { t } = useTranslation();
  function onChangeSelect(element: string | null) {
    if (element) {
      onChange({ element });
    }
  }
  function onChangeTextArea(textValue: string) {
    sizes = textValue;
    onChange({ sizes });
  }

  return (
    <div>
      <div className="input-group size-prompt-select">
        <label htmlFor="size-element-input-name-select">
          {t("element.prompt.sizeSelect.element.label")}
        </label>
        <ElementsSelect
          inputId="size-element-input-name-select"
          elements={elements}
          value={element}
          onChange={onChangeSelect}
          loading={false}
        />
      </div>
      <div className="input-group size-prompt-textarea">
        <label>{t("element.prompt.sizeSelect.textarea.label")}</label>
        <div className="highlighted-area small">
          <HighlightWithinTextarea
            value={sizes}
            highlight={[
              {
                highlight: /(?!\s)([^,]+)/g,
                className: "green",
              },
            ]}
            onChange={onChangeTextArea}
            placeholder={""}
          />
        </div>
      </div>
    </div>
  );
}

export function ColorQuestionArea({
  element,
  colors,
  onChange,
  elements,
}: ColorQuestionAreaProps) {
  const { t } = useTranslation();
  function onChangeSelect(element: string | null) {
    if (element) {
      onChange({ element });
    }
  }
  function onChangeTextArea(textValue: string) {
    colors = textValue;
    onChange({ colors });
  }

  return (
    <div>
      <div className="input-group color-prompt-select">
        <label htmlFor="color-element-input-name-select">
          {t("element.prompt.colorSelect.element.label")}
        </label>
        <ElementsSelect
          inputId="color-element-input-name-select"
          elements={elements}
          value={element}
          onChange={onChangeSelect}
          loading={false}
        />
      </div>
      <div className="input-group color-prompt-textarea">
        <label>{t("element.prompt.colorSelect.textarea.label")}</label>
        <div className="highlighted-area small">
          <HighlightWithinTextarea
            value={colors}
            highlight={[
              {
                highlight: /(?!\s)([^,]+)/g,
                className: "green",
              },
            ]}
            onChange={onChangeTextArea}
            placeholder={""}
          />
        </div>
      </div>
    </div>
  );
}

export function ManufacturerQuestionArea({
  element,
  onChange,
  elements,
}: ManufacturerQuestionAreaProps) {
  const { t } = useTranslation();
  function onChangeSelect(element: string | null) {
    if (element) {
      onChange({ element });
    }
  }

  return (
    <div>
      <div className="input-group manufacturer-prompt-select">
        <label htmlFor="manufacturer-element-input-name-select">
          {t("element.prompt.manufacturerSelect.element.label")}
        </label>
        <ElementsSelect
          inputId="manufacturer-element-input-name-select"
          elements={elements}
          value={element}
          onChange={onChangeSelect}
          loading={false}
        />
      </div>
    </div>
  );
}

export function RewriteTextQuestionArea({
  element,
  language,
  onChange,
  elements,
}: RewriteTextQuestionAreaProps | RewriteToShortTextQuestionAreaProps) {
  const { t } = useTranslation();
  function onChangeSelect(element: string | null) {
    if (element) {
      onChange({ element });
    }
  }
  const onChangeLanguage = (value: Language | null) => {
    if (value) {
      language = getLanguageTitle(value);
      onChange({ language });
    }
  };

  return (
    <div>
      <div className="input-group rewrite-prompt-select">
        <label htmlFor="rewrite-element-input-name-element">
          {t("element.prompt.rewriteSelect.element.label")}
        </label>
        <ElementsSelect
          inputId="rewrite-element-input-name-element"
          elements={elements}
          value={element}
          onChange={onChangeSelect}
          loading={false}
        />
      </div>
      <div className="input-group rewrite-prompt-language">
        <label htmlFor="rewrite-element-input-name-language">
          {t("element.prompt.rewriteSelect.language.label")}
        </label>
        <LanguageSelect
          inputId="rewrite-element-input-name-language"
          value={getLanguageFromTitle(language)}
          onChange={onChangeLanguage}
        />
      </div>
    </div>
  );
}
