import React, { Component } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import readVar from "../helpers/Config";
import { Context } from "./ContextProvider";
import { ErrorCodeMessage, InfoMessage } from "../helpers/Messages";
import { NavigateInProject } from "../helpers/Navigate";
import { useTranslation } from "react-i18next";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { t } = useTranslation();
  const context = React.useContext(Context);
  const { tokenIsValid, tokenRefreshing, token } = context;

  if (!token || !tokenIsValid(token)) {
    if (tokenRefreshing) {
      return <InfoMessage text={t("info.authenticating.text")} />;
    } else {
      return (
        <NavigateInProject
          to="/auth"
          context={context}
          replace
          state={{ location: window.location.pathname }}
        />
      );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

interface AuthRedirectProps {
  searchParams: URLSearchParams;
  t: (key: string) => string;
}

interface AuthRedirectState {
  loginStatus: string | null;
}

class AuthRedirectComponent extends Component<
  AuthRedirectProps,
  AuthRedirectState
> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  constructor(props: AuthRedirectProps) {
    super(props);
    this.state = {
      loginStatus: null,
    };
  }

  componentDidMount(): void {
    this.setState({ loginStatus: this.context.handleLogin() });
  }

  render() {
    const { t } = this.props;
    switch (this.state.loginStatus) {
      case "missing_code" || "missing_project_id":
        return <ErrorCodeMessage code={this.state.loginStatus} />;
      case "ok": {
        const location = this.props.searchParams.get("location");
        return (
          <NavigateInProject
            to={location || "/elements"}
            searchParams={this.props.searchParams}
            context={this.context}
            replace
          />
        );
      }
      default:
        return <InfoMessage text={t("info.authenticating.text")} />;
    }
  }
}

export function AuthRedirectPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  return <AuthRedirectComponent t={t} searchParams={searchParams} />;
}

interface AuthComponentProps {
  location: string;
}

export class AuthComponent extends Component<AuthComponentProps> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  componentDidMount() {
    if (this.context.loadTokenData(this.context.projectId!) !== null) {
      return (
        <NavigateInProject
          to={this.props.location}
          replace
          context={this.context}
        />
      );
    }
  }

  render() {
    const paramsConfig = new URLSearchParams();
    const clientId = readVar("REACT_APP_OAUTH2_CLIENT_ID");
    const redirectUri = readVar("REACT_APP_OAUTH2_REDIRECT_URI");

    if (!this.context.projectId) {
      return <ErrorCodeMessage code="missing_project_id" />;
    }
    if (!clientId) {
      return <ErrorCodeMessage code="missing_client_id" />;
    }
    if (!redirectUri) {
      return <ErrorCodeMessage code="missing_redirect_uri" />;
    }

    paramsConfig.append("client_id", clientId);
    paramsConfig.append(
      "redirect_uri",
      redirectUri +
        `?projectId=${this.context.projectId}&location=${this.props.location}`,
    );
    paramsConfig.append("response_type", "code");
    paramsConfig.append("grant_type", "authorization_code");
    paramsConfig.append("entity_id", this.context.projectId);

    // Workaround https://stackoverflow.com/a/1439708
    setTimeout(function () {
      window.location.href =
        readVar("REACT_APP_OAUTH2_AUTHORIZE_URL") +
        "?" +
        paramsConfig.toString();
    }, 0);

    return null;
  }
}

export const AuthPage = () => {
  const { state } = useLocation();
  const location = state.location || "/elements";
  return <AuthComponent location={location} />;
};
