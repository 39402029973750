import React from "react";
import { getPrettySourceName } from "../components/DataSources";

const styles: Record<string, React.CSSProperties> = {
  tile: {
    padding: "0 0 30px",
    margin: "10px",
    width: "100%",
    maxWidth: "320px",
    border: "1px solid #ccc7c2",
    borderRadius: "4px",
    overflow: "hidden",
  },
  tileWide: {
    padding: "0 0 30px",
    margin: "10px",
    width: "100%",
    border: "1px solid #ccc7c2",
    borderRadius: "4px",
    overflow: "hidden",
  },
  tileHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "row nowrap",
    padding: "10px",
    background: "#fcfaf9",
    borderBottom: "1px solid #ccc7c2",
  },
  tileImg: {
    height: "130px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "row nowrap",
  },
  tileBody: {
    textAlign: "center",
    margin: "0 20px",
  },
  tiles: {
    margin: "0 auto",
    maxWidth: "1080px",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexFlow: "row wrap",
  },
  tileDisabled: {
    filter: "grayscale(1) contrast(50%) brightness(140%)",
  },
  tileLinkStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "row nowrap",
    fontSize: "15px",
    margin: "10px 0 30px",
  },
};

interface DataSourceSectionProps {
  wide?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  type: string;
  header?: React.ReactNode;
}

export function Tile({
  children,
  type,
  wide,
  disabled,
  header,
}: DataSourceSectionProps) {
  return (
    <section style={wide === true ? styles.tileWide : styles.tile}>
      <header style={styles.tileHeader}>
        {header || <h3 className="link-name">{getPrettySourceName(type)}</h3>}
      </header>
      <div
        style={
          disabled === true
            ? { ...styles.tileImg, ...styles.tileDisabled }
            : styles.tileImg
        }
      >
        <img
          src={process.env.PUBLIC_URL + "/" + type + ".png"}
          alt={getPrettySourceName(type)}
        />
      </div>
      <div style={styles.tileBody}>{children}</div>
    </section>
  );
}

interface DataSourceSectionsProps {
  children: React.ReactNode;
}

export function TileItems({ children }: DataSourceSectionsProps) {
  return <div style={styles.tiles}>{children}</div>;
}

export function TileText({ children }: { children: React.ReactNode }) {
  return <p style={styles.tileLinkStatus}>{children}</p>;
}
