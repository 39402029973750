import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonDanger, ButtonPrimary } from "./Buttons";
import { Icon } from "./Icons";

interface ModalConfirmProps {
  handleClose: () => void;
  handleConfirm: () => void;
  closeText?: string;
  confirmText?: string;
  children: React.ReactNode;
}

export function ModalConfirm({
  children,
  handleClose,
  handleConfirm,
  closeText,
  confirmText,
}: ModalConfirmProps) {
  const { t } = useTranslation();

  return (
    <div id="delete-element-modal" className="modal">
      <div className="modal-content">
        <a className="close" onClick={handleClose}>
          <Icon name="off" />
        </a>
        <div>
          {children}
          <div className="submit-area">
            <div className="buttons-row">
              <ButtonPrimary onClick={handleClose}>
                {closeText || t("modal.cancel.label")}
              </ButtonPrimary>
              <ButtonDanger onClick={handleConfirm}>
                {confirmText || t("modal.confirm.label")}
              </ButtonDanger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
