import React from "react";
import { type ContextProviderType } from "../components/ContextProvider";
import { Navigate, type RelativeRoutingType, type To } from "react-router-dom";

export function getProjectId(
  searchParams?: URLSearchParams,
  context?: ContextProviderType,
) {
  if (context && context.projectId !== null) {
    return context.projectId;
  } else if (searchParams) {
    let projectId = searchParams.get("projectId");
    if (!projectId) {
      projectId = searchParams.get("project");
    }
    return projectId;
  }
}

export function getNavigateTo(
  pathname: string,
  searchParams?: URLSearchParams,
  context?: ContextProviderType,
) {
  const projectId = getProjectId(searchParams, context);
  return { pathname, search: projectId ? `?projectId=${projectId}` : "" };
}

interface NavigateInProjectProps {
  to: To;
  searchParams?: URLSearchParams;
  context?: ContextProviderType;
  replace?: boolean;
  state?: any;
  relative?: RelativeRoutingType;
}

export function NavigateInProject({
  to,
  searchParams,
  context,
  ...options
}: NavigateInProjectProps) {
  const pathname = typeof to === "string" ? to : to.pathname;
  to = getNavigateTo(pathname || "/", searchParams, context);
  return <Navigate to={to} {...options} />;
}
