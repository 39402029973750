import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { getNavigateTo } from "../helpers/Navigate";
import { Context } from "./ContextProvider";
import { Icon } from "../helpers/Icons";

const styles = {
  icon: {
    marginLeft: "10px",
    filter:
      "invert(31%) sepia(61%) saturate(1645%) hue-rotate(333deg) brightness(112%) contrast(83%)",
  },
};

const ADMIN_USERNAMES = [
  "paveldedik",
  "michaljanik",
  "radim",
  "ludekvolejnik",
  "natal",
  "davidjanek",
];

export function Header() {
  const context = useContext(Context);
  const { t } = useTranslation();

  let errorIcon;
  if (context.elementsMappings.errors.length > 0) {
    errorIcon = (
      <span title={String(t("header.navigation.error.title"))}>
        <Icon name="error" styles={styles.icon} />
      </span>
    );
  }

  let adminPage;
  if (ADMIN_USERNAMES.includes(context.token?.user_name || "")) {
    adminPage = (
      <li>
        <NavLink to={getNavigateTo("/admin", undefined, context)}>
          Admin
        </NavLink>
      </li>
    );
  }

  return (
    <div id="common-menu" className="white-block">
      <div className="d-flex flex-justify-start flex-align-center">
        <Link to={getNavigateTo("/", undefined, context)}>
          <img
            className="logo separator-right"
            src={`${process.env.PUBLIC_URL}/goat.png`}
            alt="Clicking Goat"
            title={String(t("header.logo.title"))}
          />
        </Link>
        <nav className="tabs-navigation">
          <ul>
            <li>
              <NavLink to={getNavigateTo("/elements", undefined, context)}>
                {t("header.navigation.elements.label")}
              </NavLink>
            </li>
            <li>
              <NavLink to={getNavigateTo("/sources", undefined, context)}>
                {t("header.navigation.connections.label")}
                {errorIcon}
              </NavLink>
            </li>
            {adminPage}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
