import React, { useContext, useEffect, useState } from "react";
import { Context } from "./ContextProvider";
import { ErrorCodeMessage, InfoMessage } from "../helpers/Messages";
import { useTranslation } from "react-i18next";
import { MergadoLink } from "../helpers/Mergado";
import { Paragraph } from "../helpers/Paragraphs";

interface CacheSize {
  keywords: number;
  questions: number;
  icecats: number;
  analytics: number;
}

interface QueueMetrics {
  total_count: {
    insert: number;
    process: number;
  };
  per_project_count: {
    insert: Record<number, number>;
    process: Record<number, number>;
  };
}

interface Queues {
  keywords: QueueMetrics;
  questions: QueueMetrics;
  icecats: QueueMetrics;
  analytics: QueueMetrics;
}

type QueueNames = "keywords" | "questions" | "icecats" | "analytics";

interface QueueNamesProps {
  keywords: string;
  questions: string;
  icecats: string;
  analytics: string;
}

const QUEUE_NAMES: QueueNames[] = [
  "keywords",
  "questions",
  "icecats",
  "analytics",
];
const QUEUE_NAMES_DISPLAY: QueueNamesProps = {
  keywords: "Sklik Keywords",
  questions: "OpenAI Answers",
  icecats: "IceCat Products",
  analytics: "Google Search Analytics",
};

export interface Metrics {
  cache_size: CacheSize;
  queues: Queues;
}

function getAllProjectIds(queues: Queues): number[] {
  const uniqueProjectIds = new Set(
    QUEUE_NAMES.map((name) =>
      Object.keys(queues[name].per_project_count.insert)
        .concat(Object.keys(queues[name].per_project_count.process))
        .map((projectId) => Number(projectId)),
    ).flat(),
  );
  // @ts-expect-error
  return [...uniqueProjectIds].sort((a, b) => a - b);
}

export function AdminPage(): JSX.Element {
  const context = useContext(Context);
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<Metrics | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isLoaded) {
      context.call(
        "/metrics/",
        (data) => {
          setMetrics(data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        },
        "GET",
        null,
      );
    }
  }, []);

  if (!isLoaded || metrics === null) {
    return <InfoMessage text={t("info.elements.loading.text")} />;
  }
  if (error) {
    return <ErrorCodeMessage code={error} />;
  }

  let displayPerProjectTable = false;
  const perProjectTable = (
    <table className="datagrid fixed">
      <thead>
        <tr>
          <th>Export</th>
          <th>Jméno fronty</th>
          <th>Položek k přidání do databáze</th>
          <th>Položek k načtení dat z datového zdroje</th>
        </tr>
      </thead>
      <tbody>
        {getAllProjectIds(metrics.queues).map((projectId) =>
          QUEUE_NAMES.map((queueName) => {
            const insertCount =
              metrics.queues[queueName].per_project_count.insert[projectId] ||
              0;
            const processCount =
              metrics.queues[queueName].per_project_count.process[projectId] ||
              0;

            if (insertCount + processCount > 0) {
              displayPerProjectTable = true;
            }

            let firstRow;
            if (queueName === "keywords") {
              firstRow = (
                <td rowSpan={3}>
                  <MergadoLink path={`/projects/${projectId}`}>
                    #{projectId}
                  </MergadoLink>
                </td>
              );
            }
            return (
              <tr className="no-hover" key={`${projectId}-${queueName}`}>
                {firstRow}
                <td style={firstRow ? { borderLeft: "1px solid #ccc7c2" } : {}}>
                  {QUEUE_NAMES_DISPLAY[queueName]}
                </td>
                <td>{insertCount}</td>
                <td>{processCount}</td>
              </tr>
            );
          }),
        )}
      </tbody>
    </table>
  );

  return (
    <div>
      <div className="white-block">
        <header>
          <h2>Naplnění cache</h2>
        </header>
        <table className="datagrid fixed">
          <thead>
            <tr>
              <th>Název</th>
              <th>Počet položek</th>
            </tr>
          </thead>
          <tbody>
            {QUEUE_NAMES.map((name) => (
              <tr key={`cache-${name}`}>
                <td>{QUEUE_NAMES_DISPLAY[name]}</td>
                <td>{metrics.cache_size[name]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="white-block">
        <header>
          <h2>Celkové naplnění front</h2>
        </header>
        <table className="datagrid fixed">
          <thead>
            <tr>
              <th>Jméno fronty</th>
              <th>Celkem položek k přidání do databáze</th>
              <th>Celkem položek k načtení dat z datového zdroje</th>
            </tr>
          </thead>
          <tbody>
            {QUEUE_NAMES.map((name) => (
              <tr key={`queue-${name}`}>
                <td>{QUEUE_NAMES_DISPLAY[name]}</td>
                <td>{metrics.queues[name].total_count.insert}</td>
                <td>{metrics.queues[name].total_count.process}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="white-block">
        <header>
          <h2>Naplnění front jednotlivých exportů</h2>
        </header>
        {displayPerProjectTable ? (
          perProjectTable
        ) : (
          <Paragraph>Fronty u všech exportů jsou aktuálně prázdné</Paragraph>
        )}
      </div>
    </div>
  );
}
