import React from "react";
import { useTranslation } from "react-i18next";

type TipProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export function Tip({ children }: TipProps) {
  return (
    <div className="white-block">
      <div className="tip">
        <div className="tip-img">
          <img
            src={`${process.env.PUBLIC_URL}/tip.svg`}
            alt="Clicking Goat Tip"
          />
        </div>
        <div className="tip-content">{children}</div>
      </div>
    </div>
  );
}

export function ClickingGoatTip() {
  const { t } = useTranslation();
  return (
    <Tip>
      <h2>{t("tip.howItWorks.header")}</h2>
      <p>{t("tip.howItWorks.text")}</p>
      <ol>
        <li>{t("tip.howItWorks.bullet1.text")}</li>
        <li>{t("tip.howItWorks.bullet2.text")}</li>
      </ol>
    </Tip>
  );
}
