import React from "react";
import {
  ElementsSelect,
  LanguageSelect,
  type Language,
  NOT_SET,
  PrettySelect,
  getOptionValue,
} from "../helpers/Forms";
import { type Elements } from "./ContextProvider";
import { useTranslation } from "react-i18next";

export enum ProductItem {
  "gtin" = "GTIN",
  "image" = "Image",
  "title" = "Title",
  "brand" = "Brand",
  "brand-logo" = "BrandLogo",
  "brand-part-code" = "BrandPartCode",
  "category" = "Category",
  "family" = "ProductFamily",
  "short-description" = "ShortDescription",
  "long-description" = "LongDescription",
  "bullet-point:0" = "GeneratedBulletPoints 1",
  "bullet-point:1" = "GeneratedBulletPoints 2",
  "bullet-point:2" = "GeneratedBulletPoints 3",
  "gallery-image:0" = "Gallery | Pics 1",
  "gallery-image:1" = "Gallery | Pics 2",
  "gallery-image:2" = "Gallery | Pics 3",
  "gallery-image:3" = "Gallery | Pics 4",
  "gallery-image:4" = "Gallery | Pics 5",
}

export enum ProductItemSearchable {
  "gtin" = "GTIN",
  "image" = "Image (BETA)",
  "title" = "Title (BETA)",
}

export interface Request {
  search_item_by: ProductItem;
  output_item_value: ProductItem;
  element: string;
  language: Language;
}

interface ProductInfoAreaProps {
  request: Request;
  elements: Elements;
  onChange: (request: Request) => void;
  loading: boolean;
}

interface ProductItemSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  disabled: boolean;
}

type IndexedProductItem = Record<string, number>;

export function parseIndexedProductItem(
  item: ProductItem,
): IndexedProductItem | ProductItem {
  const indexedValue = item.split(":", 2);
  if (indexedValue.length === 2) {
    return { [indexedValue[0]]: Number(indexedValue[1]) };
  } else {
    return item;
  }
}

export function dumpIndexedProductItem(
  item: ProductItem | IndexedProductItem,
): string {
  if (typeof item !== "string") {
    for (const [key, value] of Object.entries(item)) {
      return `${key}:${value}`;
    }
  }
  return item as string;
}

function getOptions(type: typeof ProductItem | typeof ProductItemSearchable) {
  return Object.entries(type).map(([key, name]) => {
    return {
      value: key,
      label: name,
    };
  });
}

function ProductItemSelect({
  inputId,
  value,
  onChange,
  disabled,
}: ProductItemSelectProps) {
  const options = getOptions(ProductItem);
  const selectedOption = options.find((option) => option.value === value);
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      options={options}
      isDisabled={disabled}
    />
  );
}

function ProductItemSearchSelect({
  inputId,
  value,
  onChange,
  disabled,
}: ProductItemSelectProps) {
  const options = getOptions(ProductItemSearchable);
  const selectedOption = options.find((option) => option.value === value);
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      options={options}
      isDisabled={disabled}
    />
  );
}

export function getDefaultRequest() {
  return {
    search_item_by: "gtin",
    output_item_value: NOT_SET,
    element: NOT_SET,
    language: "cz",
  };
}

export function ProductInfoArea({
  request,
  elements,
  onChange,
  loading,
}: ProductInfoAreaProps) {
  const { t } = useTranslation();
  const onChangeElement = (element: string | null) => {
    if (element) {
      request.element = element;
      onChange(request);
    }
  };
  const onChangeSearchBy = (value: string | null) => {
    if (value) {
      // @ts-expect-error
      request.search_item_by = parseIndexedProductItem(value);
      onChange(request);
    }
  };
  const onChangeOutputValue = (value: string | null) => {
    if (value) {
      // @ts-expect-error
      request.output_item_value = parseIndexedProductItem(value);
      onChange(request);
    }
  };
  const onChangeLanguage = (value: Language | null) => {
    if (value) {
      request.language = value;
      onChange(request);
    }
  };

  return (
    <div>
      <div className="input-group product-info-element-select">
        <label htmlFor="element-input-args">
          {t("element.select.input.label")}
        </label>
        <ElementsSelect
          inputId="element-input-args"
          elements={elements}
          value={request.element}
          onChange={onChangeElement}
          loading={loading}
        />
      </div>
      <div className="input-group product-info-element-select">
        <label htmlFor="search-item-by-select">
          {t("element.icecat.searchItemBy.select.label")}
        </label>
        <ProductItemSearchSelect
          inputId="search-item-by-select"
          value={dumpIndexedProductItem(request.search_item_by)}
          onChange={onChangeSearchBy}
          disabled={request.element === NOT_SET}
        />
      </div>
      <div className="input-group product-info-element-select">
        <label htmlFor="output-item-value-select">
          {t("element.icecat.outputItemValue.select.label")}
        </label>
        <ProductItemSelect
          inputId="output-item-value-select"
          value={dumpIndexedProductItem(request.output_item_value)}
          onChange={onChangeOutputValue}
          disabled={request.element === NOT_SET}
        />
      </div>
      <div className="input-group rewrite-prompt-language">
        <label htmlFor="rewrite-element-input-name-language">
          {t("element.icecat.language.select.label")}
        </label>
        <LanguageSelect
          inputId="rewrite-element-input-name-language"
          value={request.language}
          onChange={onChangeLanguage}
          disabled={request.element === NOT_SET}
        />
      </div>
    </div>
  );
}
