import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { type APIError, Context, type ElementMapping } from "./ContextProvider";
import { Icon } from "../helpers/Icons";
import { ButtonSecondary } from "../helpers/Buttons";
import { ErrorCodeMessage } from "../helpers/Messages";
import { invalidInputForGoogle } from "./Google";
import { invalidInputForOpenAI } from "./OpenAi";
import { getSourceTypeForInputType } from "./DataSources";

interface SampleData {
  input_data: string;
  output_data: string | null;
}

interface PreviewProps {
  t: (text: string) => string;
  input: ElementMapping["input"] | null;
  output: ElementMapping["output"] | null;
  queryId: number | null;
}

interface PreviewState {
  sampleOffset: number;
  sampleData: SampleData | null;
  sampleDataError: string | null;
  sampleDataLoading: boolean;
}

function getDefaultState() {
  return {
    sampleOffset: 0,
    sampleData: null,
    sampleDataError: null,
    sampleDataLoading: false,
  };
}

class PreviewComponent extends Component<PreviewProps, PreviewState> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  constructor(props: PreviewProps) {
    super(props);
    this.state = getDefaultState();
  }

  componentDidUpdate(prevProps: Readonly<PreviewProps>): void {
    if (
      prevProps.input?.args !== this.props.input?.args ||
      prevProps.input?.type !== this.props.input?.type ||
      prevProps.output?.type !== this.props.output?.type
    ) {
      this.setState(getDefaultState());
    }
  }

  loadSampleData(
    input: ElementMapping["input"] | null,
    output: ElementMapping["output"] | null,
    queryId: ElementMapping["query_id"],
  ) {
    this.setState({ sampleDataLoading: true });
    const okCallback = (sampleData: SampleData) => {
      this.setState({
        sampleData,
        sampleOffset: this.state.sampleOffset + 1,
        sampleDataLoading: false,
        sampleDataError: null,
      });
    };
    const errCallback = (error: APIError) => {
      let sampleOffset = this.state.sampleOffset + 1;
      let sampleDataError = "sample_preview_error";

      if (error.status === 404) {
        sampleOffset = 0;
        sampleDataError =
          this.state.sampleOffset === 0
            ? "no_preview_samples"
            : "missing_preview_samples";
      } else if (input?.type) {
        const source = getSourceTypeForInputType(input?.type);
        switch (error.status) {
          case 401:
            if (source !== "icecat") sampleDataError = `${source}_unauthorized`;
            break;
          case 403:
            if (source !== "icecat") sampleDataError = `${source}_forbidden`;
            break;
          case 429:
            if (source !== "icecat" && source !== "sklik") {
              sampleDataError = `${source}_rate_limit_error`;
            }
            break;
        }
      }

      this.setState({
        sampleDataError,
        sampleOffset,
        sampleDataLoading: false,
      });
    };
    const body = {
      input,
      output,
      query_id: queryId,
      offset: this.state.sampleOffset,
    };
    this.context.call(
      "/projects/" + this.context.projectId + "/samples/",
      okCallback,
      errCallback,
      "POST",
      body,
    );
  }

  render() {
    const { t, input, output, queryId } = this.props;
    const previewEnabled =
      input?.type &&
      input?.args &&
      output?.type &&
      output?.args &&
      !invalidInputForGoogle(input.type, input.args) &&
      !invalidInputForOpenAI(input.type, input.args);

    const handleButtonClick = (event: any) => {
      if (this.state.sampleDataLoading || !previewEnabled) {
        return;
      }
      event.currentTarget.blur();
      this.loadSampleData(input, output, queryId || undefined);
    };

    let previewData;
    let previewButtonText = <span>{t("preview.button.generate.label")}</span>;

    if (this.state.sampleDataError) {
      previewData = (
        <div className="body">
          <ErrorCodeMessage code={this.state.sampleDataError} />
        </div>
      );
    } else if (this.state.sampleData !== null) {
      previewData = (
        <div className="body">
          <div className="white-block">
            <header>
              <h5>{t("preview.block.input.label")}</h5>
            </header>
            <pre>
              <code>{this.state.sampleData.input_data}</code>
            </pre>
          </div>
          <div className="white-block">
            <header>
              <h5>{t("preview.block.output.label")}</h5>
            </header>
            <pre>
              {this.state.sampleData.output_data ? (
                <code>{this.state.sampleData.output_data}</code>
              ) : (
                <i>{t("preview.block.noValue.label")}</i>
              )}
            </pre>
          </div>
        </div>
      );
      previewButtonText = <span>{t("preview.button.regenerate.label")}</span>;
    }

    return (
      <div className="preview tile third">
        <div className="center">
          <div className="preview-button">
            <ButtonSecondary
              onClick={handleButtonClick}
              disabled={!previewEnabled}
              title={""}
            >
              <Icon name={this.state.sampleDataLoading ? "loading" : "show"} />
              {previewButtonText}
            </ButtonSecondary>
          </div>
        </div>
        {previewData}
      </div>
    );
  }
}

export const Preview = withTranslation()(PreviewComponent);
