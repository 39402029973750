import React, { useState } from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import { type Elements } from "../components/ContextProvider";

interface Suggestion {
  prefix: string;
  suffix: string;
  position: number;
}

interface TextAreaForVariablesProps {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  elements: Elements;
}

export function getElementNames(elements: Elements, prefix: string = "") {
  const names: string[] = [];
  for (const name of Object.keys(elements)) {
    const fullName = prefix.length > 0 ? `${prefix} | ${name}` : name;
    names.push(fullName);

    const children = elements[name].children;
    if (children) {
      names.push(...getElementNames(children, fullName));
    }
  }
  return names;
}

export function TextAreaForVariables({
  value,
  onChange,
  placeholder,
  elements,
}: TextAreaForVariablesProps) {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const elementsNames = getElementNames(elements);
  const elementsRegex = elementsNames
    .map((name) => name.replace("|", "\\|"))
    .join("|");

  const variableExistsRegex = new RegExp(`%(?:${elementsRegex})%`, "g");
  const variableSuggestionRegex = /%([\w\d |_]*)/;
  const variableFinishedRegex = /%([\w\d |_]+)%/g;

  const onChangeWrapper = (value: string) => {
    onChange(value);

    const cleanedValue = value.replaceAll(variableFinishedRegex, (a) =>
      " ".repeat(a.length),
    );
    const variablePart = cleanedValue.match(variableSuggestionRegex);

    if (variablePart?.length === 2) {
      const matchedCount = variablePart[1].length;
      const SHOW_MAX = 20; // show max X variables at once
      const position = variablePart.index || 0;

      let suggestNames = elementsNames
        .filter((name) =>
          name.toLowerCase().startsWith(variablePart[1].toLowerCase()),
        )
        .slice(0, SHOW_MAX)
        .map((name) => {
          return {
            prefix: name.slice(0, matchedCount),
            suffix: name.slice(matchedCount),
            position,
          };
        });

      if (suggestNames.length === 0) {
        suggestNames = elementsNames.slice(0, SHOW_MAX).map((name) => {
          return {
            prefix: "",
            suffix: name,
            position,
          };
        });
      }

      setSuggestions(suggestNames);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <div className="highlighted-area">
      <HighlightWithinTextarea
        value={value}
        highlight={[
          {
            highlight: variableExistsRegex,
            className: "green",
          },
          {
            highlight: /%([\w\d |_]+)%/g,
            className: "red",
          },
        ]}
        onChange={onChangeWrapper}
        placeholder={placeholder}
      />
      <div
        className="suggestions"
        style={{ display: suggestions.length > 0 ? "block" : "none" }}
      >
        {suggestions.map(({ prefix, suffix, position }) => (
          <a
            key={prefix + suffix + position}
            onClick={() => {
              onChangeWrapper(
                `${value.slice(0, position)}%${prefix}${suffix}%${value.slice(
                  position + prefix.length + 1,
                )}`,
              );
            }}
          >
            <code>
              <b>{prefix}</b>
              {suffix}
            </code>
          </a>
        ))}
      </div>
    </div>
  );
}
