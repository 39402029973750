import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
