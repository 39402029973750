import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ElementsSelect,
  NOT_SET,
  PrettySelect,
  getOptionValue,
} from "../helpers/Forms";
import {
  Context,
  type InputConversion,
  type ContextProviderType,
  type Elements,
} from "./ContextProvider";

export interface Request {
  dimension: "query" | "page";
  element: string;
  site_url: string;
}

export function getDefaultRequest(): Request {
  return {
    element: NOT_SET,
    site_url: NOT_SET,
    dimension: "page",
  };
}

interface GoogleSearchRequestProps {
  request: Request;
  elements: Elements;
  onChange: (request: Request) => void;
  loading: boolean;
}

interface DimensionSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: Request["dimension"]) => void;
  disabled: boolean;
}

interface SiteUrlSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: string | null) => void;
}

export function invalidInputForGoogle(
  inputType: InputConversion["type"] | null,
  inputArgs: InputConversion["args"] | null,
) {
  if (inputType === "GOOGLE_SEARCH_ANALYTICS") {
    const request = inputArgs as Request;
    return request.element === NOT_SET || request.site_url === NOT_SET;
  } else {
    return false;
  }
}

export function getOutputTypes(inputArgs: Request) {
  if (inputArgs.dimension === "page") {
    return [
      "GOOGLE_SEARCH_CLICKS",
      "GOOGLE_SEARCH_IMPRESSIONS",
      "GOOGLE_SEARCH_CTR",
      "GOOGLE_SEARCH_POSITION",
    ];
  } else {
    return [
      "GOOGLE_SEARCH_CLICKS",
      "GOOGLE_SEARCH_IMPRESSIONS",
      "GOOGLE_SEARCH_CTR",
      "GOOGLE_SEARCH_POSITION",
      "GOOGLE_SEARCH_TOP_URL",
    ];
  }
}

function DimensionSelect({
  inputId,
  value,
  onChange,
  disabled,
}: DimensionSelectProps) {
  const { t } = useTranslation();

  const options = [
    { value: "page", label: t("element.google.dimension.page.label") },
    { value: "query", label: t("element.google.dimension.query.label") },
  ];
  const selectedOption = options.find((option) => option.value === value);
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<Request["dimension"]>(option) || "page");
      }}
      options={options}
      isDisabled={disabled}
    />
  );
}

function sortDomains(domains: string[]) {
  const urls = [];
  const scDomains = [];

  for (const domain of domains) {
    if (domain.startsWith("sc-domain:")) {
      scDomains.push(domain);
    } else {
      urls.push(domain);
    }
  }

  urls.sort();
  scDomains.sort();
  return urls.concat(scDomains);
}

function SiteUrlSelect({ inputId, value, onChange }: SiteUrlSelectProps) {
  const context = useContext<ContextProviderType>(Context);

  const options = sortDomains(context.googleSites).map((site) => {
    return { value: site, label: site };
  });
  const selectedOption = options.find((option) => option.value === value);

  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      options={options}
    />
  );
}

export function GoogleSearchArea({
  request,
  elements,
  onChange,
  loading,
}: GoogleSearchRequestProps) {
  const context = useContext<ContextProviderType>(Context);

  useEffect(() => {
    if (!context.googleSitesLoaded || context.googleSitesError !== null) {
      context.loadGoogleSites();
    }
  }, []);

  const { t } = useTranslation();
  const onChangeElement = (element: string | null) => {
    if (element) {
      request.element = element;
      onChange(request);
    }
  };
  const onChangeDimension = (value: Request["dimension"]) => {
    request.dimension = value;
    onChange(request);
  };

  const onChangeSiteUrl = (value: string | null) => {
    if (value) {
      request.site_url = value;
      onChange(request);
    }
  };

  return (
    <div>
      <div className="input-group element-select">
        <label htmlFor="element-input-args">
          {t("element.select.input.label")}
        </label>
        <ElementsSelect
          inputId="element-input-args"
          elements={elements}
          value={request.element}
          onChange={onChangeElement}
          loading={loading}
        />
      </div>
      <div className="input-group site-url-select">
        <label htmlFor="output-item-value-select">
          {t("element.google.siteUrl.select.label")}
        </label>
        <SiteUrlSelect
          inputId="output-item-value-select"
          value={request.site_url || null}
          onChange={onChangeSiteUrl}
        />
      </div>
      <div className="input-group dimension-select">
        <label htmlFor="dimension-select">
          {t("element.google.dimension.select.label")}
        </label>
        <DimensionSelect
          inputId="dimension-select"
          value={request.dimension}
          onChange={onChangeDimension}
          disabled={request.element === NOT_SET}
        />
      </div>
    </div>
  );
}
