import React, { Component } from "react";
import { Context } from "./ContextProvider";
import { Icon } from "../helpers/Icons";
import { useTranslation } from "react-i18next";

interface WidgetTableProps {
  dataSourcesCount: string;
  elementsCount: string;
}

function WidgetTable({
  dataSourcesCount = "N/A",
  elementsCount = "N/A",
}: WidgetTableProps) {
  const { t } = useTranslation();

  return (
    <table className="vertical-table">
      <tbody>
        <tr>
          <th>
            <Icon name="globe" /> {t("widget.connections.label")}
          </th>
          <td>
            <b>{dataSourcesCount}</b>
          </td>
        </tr>
        <tr>
          <th>
            <Icon name="elements" /> {t("widget.elements.label")}
          </th>
          <td>
            <b>{elementsCount}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export class Widget extends Component {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  constructor(props: any) {
    super(props);
    document.body.classList.add("widget");
  }

  componentDidMount() {
    if (!this.context.elementsMappingsLoaded) {
      this.context.loadElementsMappings();
    }
  }

  render() {
    let dataSourcesCount = "N/A";
    let elementsCount = "N/A";
    if (this.context.elementsMappingsLoaded) {
      dataSourcesCount =
        this.context.elementsMappings.available_data_sources.length.toString();
      elementsCount = this.context.elementsMappings.data.length.toString();
    }

    return (
      <WidgetTable
        dataSourcesCount={dataSourcesCount}
        elementsCount={elementsCount}
      />
    );
  }
}
