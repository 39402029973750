import React from "react";

interface BigParagraphProps {
  children: React.ReactNode;
}

export function Paragraph({ children }: BigParagraphProps): JSX.Element {
  return <p className="text-block">{children}</p>;
}

export function BigParagraph({ children }: BigParagraphProps): JSX.Element {
  return <p className="text-block big">{children}</p>;
}
