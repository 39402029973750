import { ButtonText } from "./Buttons";
import React from "react";

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// For documentation see https://mergado.github.io/docs/apps/iframe-api.html
interface MergadoProps {
  startDownload: (url: string) => void;
  openWindow: (url: string) => void;
  setAppRoute: (fullAppRoute: string) => void;
  scrollTo: (topInPixels: number) => void;
  tellHeight: (heightInPixels: number) => void;
}

export declare const Mergado: MergadoProps;

interface MergadoLinkProps {
  path: string;
  children: React.ReactNode;
}

export function openWindow(url: string) {
  if (isInIframe()) {
    Mergado.openWindow(url);
  } else {
    window.open(url, "_blank", "noreferrer");
  }
}

export function MergadoLink({ path, children }: MergadoLinkProps) {
  const url = `https://app.mergado.com${path}`;
  const onClick = () => {
    openWindow(url);
  };
  return (
    <ButtonText style={{ padding: 0, minWidth: 0 }} onClick={onClick}>
      {children}
    </ButtonText>
  );
}
