import React from "react";

interface BlockProps {
  children: React.ReactNode;
  title?: string;
  smallTitle?: React.ReactNode;
}

export function Block({
  children,
  title,
  smallTitle,
}: BlockProps): JSX.Element {
  let header;
  if (title && smallTitle) {
    header = (
      <header>
        <h2>{title}</h2>
        <small>{smallTitle}</small>
      </header>
    );
  } else if (title) {
    header = (
      <header>
        <h2>{title}</h2>
      </header>
    );
  }
  return (
    <div className={`white-block ${title ? "with-title" : ""}`}>
      {header}
      {children}
    </div>
  );
}
