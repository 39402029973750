import React from "react";
import { useTranslation } from "react-i18next";

export function getMessageByCode(code: string) {
  let message = "error.unknown.text";
  switch (code) {
    case "missing_token":
      message = "error.forbidden.text";
      break;
    case "missing_code":
      message = "error.forbidden.text";
      break;
    case "missing_project_id":
      message = "error.forbidden.text";
      break;
    case "missing_client_id":
      message = "error.missingClientId.text";
      break;
    case "missing_redirect_uri":
      message = "error.missingRedirectUri.text";
      break;
    case "api_fetch_error":
      message = "error.processingData.text";
      break;
    case "element_mapping_exists":
      message = "error.elementUsed.text";
      break;
    case "sample_preview_error":
      message = "error.samplePreviewError.text";
      break;
    case "no_preview_samples":
      message = "error.noSamples.text";
      break;
    case "missing_preview_samples":
      message = "error.missingSamples.text";
      break;
    case "sklik_forbidden":
      message = "error.sklikForbidden.text";
      break;
    case "sklik_unauthorized":
      message = "error.sklikUnauthorized.text";
      break;
    case "sklik_api_error":
      message = "error.sklikApiError.text";
      break;
    case "openai_rate_limit_error":
      message = "error.openaiRateLimitError.text";
      break;
    case "openai_model_not_found":
      message = "error.openaiModelNotFound.text";
      break;
    case "openai_unauthorized":
      message = "error.openaiUnauthorized.text";
      break;
    case "openai_server_error":
      message = "error.openaiServerError.text";
      break;
    case "openai_api_error":
      message = "error.openaiApiError.text";
      break;
    case "icecat_unauthorized":
      message = "error.icecatInvalidUserName.text";
      break;
    case "icecat_full_subscription_required":
      message = "error.icecatFullSubscriptionRequired.text";
      break;
    case "icecat_forbidden":
      message = "error.icecatPermissionError.text";
      break;
    case "icecat_api_error":
      message = "error.icecatApiError.text";
      break;
    case "google_rate_limit_error":
      message = "error.googleRateLimitError.text";
      break;
    case "google_forbidden":
      message = "error.googleForbiddenError.text";
      break;
    case "google_no_sites":
      message = "error.googleNoSites.text";
      break;
    case "google_unauthorized":
      message = "error.googleUnauthorized.text";
      break;
    case "google_api_error":
      message = "error.googleApiError.text";
      break;
  }
  return message;
}

interface CodeProps {
  code: string;
}

export function ErrorCodeMessage({ code }: CodeProps) {
  const { t } = useTranslation();
  return <ErrorMessage text={t(getMessageByCode(code))} />;
}

export function WarningCodeMessage({ code }: CodeProps) {
  const { t } = useTranslation();
  return <WarningMessage text={t(getMessageByCode(code))} />;
}

interface MessageProps {
  text: React.ReactNode;
}

export function InfoMessage({ text }: MessageProps) {
  return <div className="message">{text}</div>;
}

export function SuccessMessage({ text }: MessageProps) {
  return <div className="message success">{text}</div>;
}

export function WarningMessage({ text }: MessageProps) {
  return <div className="message warning">{text}</div>;
}

export function ErrorMessage({ text }: MessageProps) {
  return <div className="message error">{text}</div>;
}
