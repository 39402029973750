import React from "react";
import "./App.css";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

import Header from "./components/Header";
import { ElementsPage } from "./components/Elements";
import { DataSourcesPage } from "./components/DataSources";
import { ContextProvider } from "./components/ContextProvider";
import {
  AuthPage,
  AuthRedirectPage,
  ProtectedRoute,
} from "./components/Authentication";
import { Widget } from "./components/Widget";
import { getNavigateTo } from "./helpers/Navigate";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { AdminPage } from "./components/Admin";

void i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    cs: {
      translation: require("./locales/cs/translation.json"),
    },
    sk: {
      translation: require("./locales/sk/translation.json"),
    },
    hu: {
      translation: require("./locales/hu/translation.json"),
    },
  },
  fallbackLng: "cs",
  interpolation: { escapeValue: false },
});

function App(): JSX.Element {
  const [searchParams] = useSearchParams();

  return (
    <ContextProvider>
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <Navigate to={getNavigateTo("/elements", searchParams)} replace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sources"
          element={
            <ProtectedRoute>
              <Header />
              <DataSourcesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/elements"
          element={
            <ProtectedRoute>
              <Header />
              <ElementsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Header />
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/widget"
          element={
            <ProtectedRoute>
              <Widget />
            </ProtectedRoute>
          }
        />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/auth/callback" element={<AuthRedirectPage />} />
      </Routes>
    </ContextProvider>
  );
}

export default App;
