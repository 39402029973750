import React from "react";

export interface IconProps {
  name: string;
  styles?: React.CSSProperties;
  className?: string;
}

export function Icon({ name, styles, className }: IconProps) {
  return (
    <svg
      className={`icon icon-${name} ${className}`}
      aria-hidden="true"
      style={styles}
    >
      <use xlinkHref={`${process.env.PUBLIC_URL}/muil.icons.svg#${name}`}></use>
    </svg>
  );
}
