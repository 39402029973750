import React from "react";

export type ButtonProps = {
  children: React.ReactNode;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({
  children,
  className,
  ...args
}: ButtonProps): JSX.Element {
  return (
    <button className={`button ${className}`} {...args}>
      {children}
    </button>
  );
}

export function ButtonPrimary({ children, ...args }: ButtonProps): JSX.Element {
  return (
    <Button className="primary" {...args}>
      {children}
    </Button>
  );
}

export function ButtonSecondary({
  children,
  ...args
}: ButtonProps): JSX.Element {
  return (
    <Button className="secondary" {...args}>
      {children}
    </Button>
  );
}

export function ButtonText({ children, ...args }: ButtonProps): JSX.Element {
  return (
    <Button className="text" {...args}>
      {children}
    </Button>
  );
}

export function ButtonPlain({ children, ...args }: ButtonProps): JSX.Element {
  return (
    <Button className="plain" {...args}>
      {children}
    </Button>
  );
}

export function ButtonDanger({ children, ...args }: ButtonProps): JSX.Element {
  return (
    <Button className="danger" {...args}>
      {children}
    </Button>
  );
}

export function ButtonSecondaryDanger({
  children,
  ...args
}: ButtonProps): JSX.Element {
  return (
    <Button className="secondary danger" {...args}>
      {children}
    </Button>
  );
}
